import ActionButtons from '../ActionButtons/ActionButtons';
import {useCallback, useEffect, useMemo} from 'react';
import {FormProvider, useForm, useWatch} from 'react-hook-form';
import FormNotification from './form-components/components/FormNotification/FormNotification';
import FormSegmentComponent from './form.segment';
import {
	FormModel,
	DynamicFormDataV2,
	FormSegment,
} from '../../../../common/src/application/dynamicFormUtil/types/DynamicForm.types';
import useDynamicForm from '../hooks/useDynamicForm';
import {ConfigProvider} from '../utils/config.mapper';
import {IApiExecutorService} from '../utils/core.mapper';
import React from 'react';
import _ from 'lodash';

const DynamicForm = ({
	dynamicFormData,
	formModel,
	onSubmit,
	onError,
	actionButtons,
	notification,
	callbackHandlers,
	linkCompHandlers,
	remoteDropdownRequests,
	fileUploadHandler,
	configProvider,
	requestExecutor,
	formMethodsRef,
	isViewOnlyMode,
	sessionStorageKeys,
	customErrorMap,
}: {
	dynamicFormData: DynamicFormDataV2;
	formModel?: FormModel;
	onSubmit?: any;
	onError?: any;
	actionButtons?: any;
	notification?: any;
	callbackHandlers?: any;
	linkCompHandlers?: any;
	remoteDropdownRequests?: any;
	fileUploadHandler?: any;
	configProvider?: ConfigProvider;
	requestExecutor?: IApiExecutorService;
	formMethodsRef?: any;
	isViewOnlyMode?: boolean;
	sessionStorageKeys?: Record<string, string>;
	customErrorMap?: Record<string, string>;
}): JSX.Element => {
	// useForm hook
	const formMethods = useForm<any>({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		shouldUnregister: true,
		shouldFocusError: false,
	});

	const {
		register,
		handleSubmit,
		control,
		setFocus,
		formState: {errors},
		reset,
		getValues,
		setError,
		clearErrors,
		setValue,
	} = formMethods;

	const {
		fieldsToWatch,
		formRenderConstraints,
		updateConstraints,
		formConfig,
		addSection,
		removeSection,
		formModel: _formModel,
		defaultStateAndConstraints,
	} = useDynamicForm(dynamicFormData, formModel);

	const dynamicParentFields = useWatch({
		control,
		name: fieldsToWatch,
	});

	useEffect(() => {
		if (customErrorMap != null) {
			Object.keys(customErrorMap).forEach(errorKey => {
				setError(errorKey, {type: 'custom', message: customErrorMap[errorKey]});
			});
		}
	}, [customErrorMap, setError]);

	// this is to remove {value: 'foo', label: 'bar'} from data
	const onSubmitWrapper = useCallback(
		(handler: any) => (formData: Record<string, any>) => {
			Object.keys(formData).forEach((formEntry: string) => {
				// for dropdown and other fields with {option: string, value: any}[] type
				if (
					Array.isArray(formData[formEntry]) &&
					formData[formEntry]?.length !== 0 &&
					formData[formEntry][0]?.value != null &&
					formData[formEntry][0]?.label != null &&
					formData[formEntry][0] != null
				) {
					let newEntry: Array<any> = [];
					formData[formEntry].forEach((entryOption: any) => {
						newEntry.push(entryOption.value ?? entryOption);
					});
					formData[formEntry] =
						newEntry.length !== 0 ? newEntry : formData[formEntry];
				}
				// for dropdown and other fields with {option: string, value: any} type
				formData[formEntry] = formData[formEntry]?.value ?? formData[formEntry];
			});
			if (handler) {
				handler(formData);
			}
			return formData;
		},
		[],
	);

	const submitHandler = useCallback(
		(submitHandler: any, errorHandler: any) =>
			handleSubmit(onSubmitWrapper(submitHandler), errorHandler),
		[handleSubmit],
	);

	const resetHandler: Function = () => {
		reset();
	};

	useEffect(() => {
		const values = getValues();
		if (values != null && Object.keys(values).length !== 0) {
			updateConstraints(getValues());
		}
	}, [dynamicParentFields, defaultStateAndConstraints]);

	const singleSegment = useMemo(
		() => formConfig.formData?.length !== 1,
		[formConfig],
	);

	const setValuesOnAddSegment = (formSegment: FormSegment) => {
		let dataToSetForSection: Record<string, any> = _.cloneDeep(getValues());
		const replicableSectionName = formSegment.originalName;
		const replicableSectionIndex = replicableSectionName
			? Object.keys(dataToSetForSection).indexOf(replicableSectionName)
			: -1;
		let replicableSegment: Record<string, any>[] =
			dataToSetForSection[
				Object.keys(dataToSetForSection)[replicableSectionIndex]
			];
		replicableSegment = replicableSegment.filter(function (element) {
			return element !== undefined;
		});
		setValue(
			Object.keys(dataToSetForSection)[replicableSectionIndex],
			replicableSegment,
		);
		dataToSetForSection = _.cloneDeep(getValues());
		addSection(formSegment.name);
		setTimeout(() => {
			if (replicableSectionName && replicableSectionIndex !== -1) {
				dataToSetForSection[replicableSectionName]?.forEach(
					(data: Record<string, any>, idx: number) => {
						if (data) {
							Object.keys(data).forEach((k: string) => {
								setValue(
									`${
										Object.keys(dataToSetForSection)[replicableSectionIndex]
									}.${idx}.${k}`,
									data[k],
								);
							});
						}
					},
				);
			}
		}, 100);
	};

	const setValuesOnRemoveSegment = (formSegment: FormSegment) => {
		const dataToSetForSection: Record<string, any> = _.cloneDeep(getValues());
		const indexToBeIgnored = parseInt(formSegment.name?.split('.')[1]);
		const replicableSectionName = formSegment.originalName;
		const replicableSectionIndex = replicableSectionName
			? Object.keys(dataToSetForSection).indexOf(replicableSectionName)
			: -1;
		removeSection(formSegment.name);
		setTimeout(() => {
			if (replicableSectionName && replicableSectionIndex !== -1) {
				dataToSetForSection[
					Object.keys(dataToSetForSection)[replicableSectionIndex]
				].forEach((data: Record<string, any>, idx: number) => {
					if (data && indexToBeIgnored !== idx) {
						if (idx < indexToBeIgnored) {
							Object.keys(data).forEach((k: string) => {
								setValue(
									`${
										Object.keys(dataToSetForSection)[replicableSectionIndex]
									}.${idx}.${k}`,
									data[k],
								);
							});
						} else {
							Object.keys(data).forEach((k: string) => {
								setValue(
									`${
										Object.keys(dataToSetForSection)[replicableSectionIndex]
									}.${idx - 1}.${k}`,
									data[k],
								);
							});
						}
					}
				});
			}
		}, 100);
	};

	useEffect(() => {
		if (formMethodsRef) {
			formMethodsRef.getValues = getValues;
			formMethodsRef.onSubmit = submitHandler;
			formMethodsRef.reset = reset;
			formMethodsRef.errors = errors;
			formMethodsRef.setFocus = setFocus;
			formMethodsRef.setValue = setValue;
		}
	}, [
		formMethodsRef,
		getValues,
		reset,
		submitHandler,
		setFocus,
		errors,
		setValue,
	]);

	return (
		<FormProvider {...formMethods}>
			{notification && (
				<FormNotification
					content={notification.message}
					name={notification.focusTarget}
				/>
			)}

			<form
				id={`form_dynamicForm_${formConfig?.formData[0]?.name}`}
				autoComplete="off"
				className={'dynamicForm'}
				noValidate>
				{formConfig.formData.map((formSegment: FormSegment, key: number) => (
					<React.Fragment key={key}>
						<FormSegmentComponent
							callbackHandlers={callbackHandlers}
							formStateAndConstraints={formRenderConstraints}
							showHeader={singleSegment}
							formSegment={formSegment}
							register={register}
							setValue={setValue}
							formModel={_formModel}
							errors={errors}
							control={control}
							setFocus={setFocus}
							setError={setError}
							clearErrors={clearErrors}
							key={formSegment.name}
							linkCompHandlers={linkCompHandlers}
							remoteDropdownRequests={remoteDropdownRequests}
							fileUploadHandler={fileUploadHandler}
							configProvider={configProvider}
							requestExecutor={requestExecutor}
							onAddSegment={() => {
								setValuesOnAddSegment(formSegment);
							}}
							onRemoveSegment={() => {
								setValuesOnRemoveSegment(formSegment);
							}}
							isViewOnlyMode={isViewOnlyMode}
							sessionStorageKeys={sessionStorageKeys}
						/>
					</React.Fragment>
				))}
				{actionButtons != null && (
					<ActionButtons
						className="btnContainer"
						actionButtons={actionButtons}
						resetHandler={resetHandler}
						submitHandler={submitHandler(onSubmit, onError)}></ActionButtons>
				)}
			</form>
		</FormProvider>
	);
};

export default DynamicForm;
